'use client'

import clsx from 'clsx'
import { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import ModalCart from '../Modal/ModalCart'
import { ModalContrast } from '../Modal/ModalContrast'
import { Switch } from '../Switch/Switch'

import { headerMock } from '../../../../tests/mock/mock-header'
import { useIsomorphicLayoutEffect } from '../../../core/hook/useIsomorphicLayout'
import { MenuBlockItem } from '../../../domain/menuBlock.types'
import { CartContext } from '../../context/CartContext'
import { ConfigContext, useTranslation } from '../../context/ConfigContext'
import { ContrastContext } from '../../context/ContrastContext'
import { UserContext } from '../../context/UserContext'
import { useIsMobileOrTablet } from '../../utils/screen.utils'
import HeaderNavigationDesktop from './HeaderNavigationDesktop'
import HeaderNavigationMobile from './HeaderNavigationMobile'
import HeaderSearch from './HeaderSearch'
import HeaderAlertNotification from './HeaderAlertNotification'
import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'
import Link from '../Link/Link'

interface HeaderProps {
  defaultContrast?: boolean
  menuItems?: MenuBlockItem[]
  hideItems?: boolean
  infoPush?: ReactNode
  isSimpleHeader: boolean
  hasBackground?: boolean
  noBorder?: boolean
}

const infoPushMarginCalculator = (isTablet, infoPushHeight, navHeight) => {
  // Ref for the infoPush

  const mainElements = typeof document !== 'undefined' && document.querySelectorAll('main')

  const relativeHeader =
    typeof document !== 'undefined' && document.querySelector('.header.hide-items')
  const activeHeader = typeof document !== 'undefined' && document.querySelector('.header.active')

  const deliveryInfoPush =
    typeof document !== 'undefined' && document.querySelector('.has-message-push')
  const close =
    typeof document !== 'undefined' && document.querySelector('.message-push .message-push-close')

  const heroHeadless = typeof document !== 'undefined' && document.querySelector('.header-media')
  const heroLegacy1 = typeof document !== 'undefined' && document.querySelector('.hero-container')
  const heroLegacy2 =
    typeof document !== 'undefined' && document.querySelector('.banner-top-wrapper')

  const hero = heroHeadless || heroLegacy1 || heroLegacy2

  const extraMargin = isTablet ? 10 : 35
  const fullMargin = `${infoPushHeight + navHeight + extraMargin}px`
  const onlyNavMargin = `${navHeight + extraMargin}px`

  if (mainElements && mainElements.length > 0) {
    mainElements.forEach((mainElement) => {
      const handleClose = (newMargin) => {
        close &&
          close.addEventListener('click', () => {
            mainElement.style.marginTop = newMargin
          })
      }
      ////// For menu absolute + hero
      if (hero) {
        mainElement.classList.add('absolute-hero') // Class only for debug, do not style !
        // If deliveryInfoPush is on the page
        if (deliveryInfoPush) {
          mainElement.style.marginTop = `${infoPushHeight}px`
          // On close of the deliveryInfoPush
          handleClose('0px')
        }
        // If deliveryInfoPush is not on the page
        if (!deliveryInfoPush) {
          mainElement.style.marginTop = '0px'
        }
      } else {
        ////// For menu absolute + no hero
        if (activeHeader) {
          mainElement.classList.add('absolute-no-hero') // Class only for debug, do not style !
          // If deliveryInfoPush is on the page
          if (deliveryInfoPush) {
            mainElement.style.marginTop = fullMargin
            // On close of the deliveryInfoPush
            handleClose(onlyNavMargin)
          }
          // If deliveryInfoPush is not on the page
          if (!deliveryInfoPush) {
            mainElement.style.marginTop = onlyNavMargin
          }
          ////// For menu relative + no hero
        } else if (relativeHeader) {
          mainElement.classList.add('relative-no-hero') // Class only for debug, do not style !
          // If deliveryInfoPush is on the page
          if (deliveryInfoPush) {
            mainElement.style.marginTop = '0px'
            // On close of the deliveryInfoPush
            handleClose('0px')
          }
          // If deliveryInfoPush is not on the page
          if (!deliveryInfoPush) {
            mainElement.style.marginTop = '0px'
          }
        }
      }
    })
  }
}

const Header = ({
  menuItems = headerMock.data.menu.items as MenuBlockItem[],
  hideItems = false,
  infoPush,
  isSimpleHeader,
  hasBackground,
  noBorder,
  defaultContrast,
}: HeaderProps) => {
  const [headerMobileOpened, setHeaderMobileOpened] = useState<boolean>(false)
  const [searchOpened, setSearchOpened] = useState<boolean>(false)
  const { addModal, removeModal } = useContext(ModalContext) as ModalContextType
  const { storeConfig, isEcommerce } = useContext(ConfigContext)
  const [itemQuantity, setItemQuantity] = useState<number>(0)
  const t = useTranslation()
  const [isMounted, setMounted] = useState(false);

  const { contrast, setContrast } = useContext(ContrastContext)
  const { cart } = useContext(CartContext)
  const tel = storeConfig.general_store_information_phone

  const isTablet = useIsMobileOrTablet()

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (headerMobileOpened) {
      typeof document !== 'undefined' && document.body.classList.add('fullscreen-header')
    } else {
      typeof document !== 'undefined' && document.body.classList.remove('fullscreen-header')
    }
  }, [headerMobileOpened])

  const classes = clsx({
    header: true,
    contrast: contrast,
    primary: !contrast,
    'header-mobile-opened': headerMobileOpened,
    'header-search-opened': searchOpened,
    'hide-items': hideItems,
    'has-background': hasBackground,
    'no-border': noBorder,
    active: !defaultContrast,
  })

  useEffect(() => {
    setItemQuantity(cart.items.reduce((total, cartItem) => total + cartItem.quantity, 0) || 0)
  }, [cart])

  useIsomorphicLayoutEffect(() => {
    const headerEl = document.querySelector('header')
    const infoPush = document.querySelector('.delivery-infoPush')

    const onScrollHeader = () => {
      if (window.scrollY > 82 && !hideItems) {
        headerEl?.classList.add('header-scroll')
        infoPush?.classList.add('header-scroll')

        return
      }

      headerEl?.classList.remove('header-scroll')
      infoPush?.classList.remove('header-scroll')
    }

    window.addEventListener('scroll', onScrollHeader)

    return () => {
      window.removeEventListener('scroll', onScrollHeader)
    }
  }, [hideItems])

  const { isLogged } = useContext(UserContext)

  const account: string = isLogged ? '/customer/account' : '/customer/login'
  const wishlist: string = isLogged ? '/customer/dashboard/wishlist' : '/customer/login'

  useEffect(() => {
    // Let the document know when the mouse is being used
    document.body.addEventListener('mousedown', function () {
      document.body.classList.add('using-mouse')
    })

    // Re-enable focus styling when Tab is pressed
    document.body.addEventListener('keydown', function (event) {
      if (event.keyCode === 9) {
        document.body.classList.remove('using-mouse')
      }
    })
  }, [])

  // Calculate height of nav and infoPush to add margin on the main to avoid overlapping of content
  const infoPushRef = useRef<HTMLDivElement | null>(null)
  const navRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    infoPushMarginCalculator(isTablet, infoPushRef.current?.clientHeight, navRef.current?.clientHeight)
  }, [isMounted, isTablet])


  return (
    <header className={classes}>
      {infoPush && (
        <div ref={infoPushRef} className='delivery delivery-infoPush'>
          {infoPush}
        </div>
      )}

      <div ref={navRef} className='container-menu'>
        <div className={`wrapper-header ${isSimpleHeader ? 'simple-header' : ''}`}>
          <ul className='header-helpers'>
            {isTablet ? (
              <>
                <li className='menu'>
                  <button
                    aria-label='menu'
                    type='button'
                    onClick={() => {
                      setHeaderMobileOpened(true)
                    }}>
                    <Icon iconName={Icons.MENU} />
                  </button>
                </li>
                <li className='contrast-icon'>
                  <button
                    aria-label='Change contrast'
                    onClick={() => {
                      const uuid = crypto.randomUUID()
                      document.body.classList.add('contrast-modal-open')
                      addModal({
                        uuid,
                        component: (
                          <ModalContrast
                            uuid={uuid}
                            contrast={contrast}
                            onClick={() => {
                              setContrast(!contrast)
                              removeModal(uuid)
                              document.body.classList.remove('contrast-modal-open')
                            }}
                          />
                        ),
                      })
                    }}
                    type='button'>
                    {contrast ? (
                      <Icon iconName={Icons.EYE_EMPTY} />
                    ) : (
                      <Icon iconName={Icons.EYE_OFF} />
                    )}
                  </button>
                </li>
              </>
            ) : (
              <>
                <li className='helpers-border hidden lg-flex'>
                  <Switch
                    label={t('header-contrast-label', {}, false)}
                    color='white'
                    value={contrast}
                    onChange={() => setContrast(!contrast)}
                    checked={contrast}
                    aria-pressed={contrast}
                  />
                </li>
                {tel && (
                  <li className='numbers hidden lg-flex'>
                    <Link title={tel} href='/contact'>
                      {tel}
                    </Link>
                  </li>
                )}
              </>
            )}
          </ul>
          <div className='header-logo'>
            <Link href='/' aria-label='home-link' ariaLabel={t('MESSIKA PARIS', {}, false)}>
              <Icon title={'Messika'} iconName={Icons.MESSIKA_LOGO} width={114} height={32} />
            </Link>
          </div>
          <div className={`header-tools ${contrast && 'icon-contrast'}`}>
            <ul>
              {isTablet && tel ? (
                <li>
                  <a title={tel} href={`tel:${tel.replace(/\s/g, '')}`}>
                    <Icon iconName={Icons.PHONE} />
                  </a>
                </li>
              ) : (
                <>
                  {!isTablet && (
                    <li className='hidden lg-flex'>
                      <button
                        aria-label={t('Search', {}, false)}
                        type='button'
                        className='button--search'
                        onClick={() => setSearchOpened(true)}>
                        <Icon title={t('global-cta-search', {}, false)} iconName={Icons.SEARCH} />
                      </button>
                    </li>
                  )}
                  <li className='hidden lg-flex'>
                    <Link
                      className='button--wishlist'
                      href={wishlist}
                      ariaLabel={t('Wishlist', {}, false)}>
                      <Icon
                        title={t('Wishlist', {}, false)}
                        iconName={Icons.HEART}
                        width={22}
                        height={20}
                      />
                    </Link>
                  </li>
                </>
              )}

              {isEcommerce() && (
                <li>
                  <button
                    type='button'
                    className='button--cart'
                    aria-label={t('Cart', {}, false)}
                    onClick={() => {
                      const uuid = crypto.randomUUID()
                      addModal({
                        uuid,
                        component: <ModalCart uuid={uuid} onClose={() => removeModal(uuid)} />,
                      })
                    }}>
                    <Icon title={t('Shopping Cart', {}, false)} iconName={Icons.SHOPPING_BAG} />
                    <span className={`button--cart--counter ${itemQuantity > 0 && 'visible'}`}>
                      {itemQuantity}
                    </span>
                  </button>
                </li>
              )}

              {!isTablet && (
                <li className='hidden lg-flex'>
                  <Link
                    className='button--account'
                    href={account}
                    ariaLabel={t('Account', {}, false)}>
                    <Icon title={t('My Account', {}, false)} iconName={Icons.USER} />
                  </Link>
                </li>
              )}
            </ul>
          </div>
          {!isTablet && searchOpened && <HeaderSearch onClose={() => setSearchOpened(false)} />}
        </div>
        {!isTablet && menuItems.length > 0 && !isSimpleHeader ? (
          <nav className='header-nav hidden lg-block'>
            <HeaderNavigationDesktop menuItems={menuItems} />
          </nav>
        ) : null}

        {isTablet && headerMobileOpened && (
          <HeaderNavigationMobile
            onClose={() => setHeaderMobileOpened(false)}
            menuItems={menuItems}
          />
        )}
      </div>

      <HeaderAlertNotification />
    </header>
  )
}

export default Header
